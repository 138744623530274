import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { DateTime } from 'luxon';

import { DateRangeInput, Labeled } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep5Regular } from './RequestStep5Regular';
import { RequestStep5Irregular } from './RequestStep5Irregular';
import { CareKindNew } from 'model';
import { count3DaysLater } from 'page/customer/request';

export const RequestStep4 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const isValid =
    customerStore.applyForm.careStartAt &&
    DateTime.fromFormat(
      customerStore.applyForm.careStartAt,
      'yyyy-MM-dd',
    ).diffNow('months').months <= 2 &&
    DateTime.fromFormat(
      customerStore.applyForm.careStartAt,
      'yyyy-MM-dd',
    ).diffNow('days').days >= count3DaysLater();

  const isValidHasEndedDate =
    customerStore.applyForm.careStartAt &&
    customerStore.applyForm.careEndAt &&
    DateTime.fromFormat(customerStore.applyForm.careEndAt, 'yyyy-MM-dd').diff(
      DateTime.fromFormat(customerStore.applyForm.careStartAt, 'yyyy-MM-dd'),
      'days',
    ).days >= 28;

  const onClickNext = () => {
    if (customerStore.applyForm.type === CareKindNew.Regular)
      onNext(RequestStep5Regular);
    else onNext(RequestStep5Irregular);
  };

  const checkDisabled = () => {
    if (
      !!customerStore.applyForm.careStartAt &&
      !customerStore.applyForm.careEndAt
    ) {
      return !isValid || !customerStore.applyForm.careStartAt;
    } else {
      return !isValid || !isValidHasEndedDate;
    }
  };
  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>
        돌봄 시작일과
        <br />
        종료일을 선택해주세요.
      </Title>
      <Space height={8} />
      <Subtitle>
        *시작일은 최대 2개월 이내부터 선택 가능하며
        <br />
        &nbsp;&nbsp;최소 일주일 전에 신청해주시면 가장 좋아요!
      </Subtitle>
      <Space height={24} />

      <Labeled label="시작일">
        <DateRangeInput
          hasIcon
          isStart
          startDate={
            customerStore.applyForm.careStartAt
              ? DateTime.fromFormat(
                  customerStore.applyForm.careStartAt,
                  'yyyy-MM-dd',
                )
              : null
          }
          endDate={
            customerStore.applyForm.careEndAt
              ? DateTime.fromFormat(
                  customerStore.applyForm.careEndAt,
                  'yyyy-MM-dd',
                )
              : null
          }
          onChange={(start, end) => {
            customerStore.applyForm.careStartAt = start?.toFormat('yyyy-MM-dd');
            customerStore.applyForm.careEndAt = end?.toFormat('yyyy-MM-dd');
          }}
        />
      </Labeled>
      <Space height={28} />

      <Labeled label="종료일 (미정인 경우 선택하지 않으셔도 됩니다)">
        <DateRangeInput
          hasIcon
          isStart={false}
          startDate={
            customerStore.applyForm.careStartAt
              ? DateTime.fromFormat(
                  customerStore.applyForm.careStartAt,
                  'yyyy-MM-dd',
                )
              : null
          }
          endDate={
            customerStore.applyForm.careEndAt
              ? DateTime.fromFormat(
                  customerStore.applyForm.careEndAt,
                  'yyyy-MM-dd',
                )
              : null
          }
          onChange={(start, end) => {
            customerStore.applyForm.careStartAt = start?.toFormat('yyyy-MM-dd');
            customerStore.applyForm.careEndAt = end?.toFormat('yyyy-MM-dd');
          }}
        />
      </Labeled>
      <Space height={128} />
      <Bottom
        progress={(1 / 11) * 4}
        disabled={checkDisabled()}
        onClickNext={onClickNext}
      />
    </div>
  );
});
