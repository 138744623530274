import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';

import { Checkbox, Labeled } from 'atom/input';
import { Space, HorizontalLayout } from 'atom/layout';
import { Black14 } from 'atom/text';
import { Propensities } from 'constant';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestPersonalityCheck4 } from './RequestPersonalityCheck4';
import moment from 'moment';

export const checkBabyAgeMonth = birthday => {
  const _today = moment();
  const _birthDate = moment(birthday, 'YYYYMMDD');
  const diffMonth = _today.diff(_birthDate, 'months', true);
  return diffMonth;
};

export const RequestChildInfo1 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const checkBirthDate = birthday => {
    const pattern = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
    return pattern.test(birthday);
  };

  const onSubmit = () => {
    if (!checkBirthDate(customerStore.childForm.birthdate)) {
      showConfirm('생년월일 숫자 8자리를 정확히 입력해주세요.');
      return;
    }
    if (customerStore.childForm.name.length === 0) {
      showConfirm('아이 이름을 입력해주세요.');
      return;
    }
    if (customerStore.childForm.birthdate.length !== 8) {
      showConfirm('생년월일 8자리를 입력해주세요.');
      return;
    }
    if (checkBabyAgeMonth(customerStore.childForm.birthdate) < 12) {
      showConfirm('아이 나이 만 12개월 이상만 신청 가능합니다.');
      return;
    }

    onNext(RequestPersonalityCheck4);
  };

  //console.log(customerStore.childForm);

  return (
    <>
      <Title>아이 기본 정보</Title>
      <Space height={50} />

      <Labeled label="이름">
        <TextField
          variant="outlined"
          placeholder="입력란"
          style={{ width: '100%' }}
          error={
            customerStore.childForm.name.length <= 2 ||
            customerStore.childForm.name.length > 16
          }
          value={customerStore.childForm.name}
          onChange={e => (customerStore.childForm.name = e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled label="생년월일 8자리">
        <TextField
          variant="outlined"
          placeholder="YYYYMMDD"
          type="number"
          inputProps={{ maxLength: 8 }}
          style={{ width: '100%' }}
          error={customerStore.childForm.birthdate.length !== 8}
          value={customerStore.childForm.birthdate}
          onChange={e => (customerStore.childForm.birthdate = e.target.value)}
        />
      </Labeled>
      <Space height={20} />

      <Labeled label="성별">
        <HorizontalLayout>
          <Checkbox
            value={customerStore.childForm.sex === 1}
            onChange={() => (customerStore.childForm.sex = 1)}
          />
          <Space width={8} />
          <Black14>여자아이</Black14>
          <Space width={40} />
          <Checkbox
            value={customerStore.childForm.sex === 0}
            onChange={() => (customerStore.childForm.sex = 0)}
          />
          <Space width={8} />
          <Black14>남자아이</Black14>
        </HorizontalLayout>
      </Labeled>
      <Space height={150} />
      <Bottom
        disabled={
          customerStore.childForm.birthdate.length === 0 ||
          customerStore.childForm.name.length === 0
        }
        progress={(1 / 2) * 1}
        onClickNext={onSubmit}
      />
    </>
  );
});
