import React from 'react';
import { usePrices } from 'state/hook/value';
import { price } from 'util/index';
import { Table } from './Table';

export const HourlyPricingTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th>인원</th>
          <th>시급</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이 1명</td>
          <td>10,000원</td>
        </tr>
        <tr>
          <td>아이 2명</td>
          <td>13,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};

export const WorkMinTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>서비스 종류</th>
          <th style={{ width: '50%' }}>최소 업무 시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>등원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>하원 + 놀이돌봄</td>
          <td>3시간 이상</td>
        </tr>
        <tr>
          <td>등하원 + 놀이돌봄</td>
          <td>5시간 이상</td>
        </tr>
        <tr>
          <td>놀이돌봄만</td>
          <td>3시간 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};

export const PremiumServiceHourlyPricingTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>이용</th>
          <th style={{ width: '50%' }}>시급</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>아이 1명</td>
          <td>12,000원</td>
        </tr>
        <tr>
          <td>아이 2명</td>
          <td>15,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};

export const ExtraServiceHourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table style={{ width: '100%', maxWidth: '400px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>이용 시간</th>
          <th style={{ width: '50%' }}>시급</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1시간</td>
          <td>12,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};
