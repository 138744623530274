import React from 'react';
import { observer } from 'mobx-react';

import { MultiSelect } from 'atom/input';
import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep8 } from './RequestStep8';

export const RequestPersonalityCheck4 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();

  const onSubmit = async () => {
    const form = customerStore.childForm;

    if (form.id) {
      //수정
      await customerStore.updateChild(customerStore.childForm);
    } else {
      //추가
      await customerStore.addChild(customerStore.childForm);
    }
    onNext(RequestStep8);
  };

  return (
    <div style={{ padding: '40px 20px' }}>
      <Title>
        아이에 대해
        <br />좀 더 알려주세요 :)
      </Title>
      <Space height={8} />
      <Subtitle>*성향, 병력, 낮잠시간 등 자유롭게 알려주세요.</Subtitle>
      <Space height={41} />

      <textarea
        placeholder="입력란 (200자 이내)"
        maxLength={200}
        value={customerStore.childForm.memo}
        onChange={e => (customerStore.childForm.memo = e.target.value)}
      />
      <Space height={90} />
      <Bottom
        disabled={customerStore.childForm.memo.length === 0}
        progress={(1 / 5) * 5}
        onClickNext={onSubmit}
      />
    </div>
  );
});
