import { CareType, CareTypeNew } from 'model';

export const getStartTimePlaceholder = (type: CareTypeNew) =>
  ({
    [CareTypeNew.DropOff]: '07:00',
    [CareTypeNew.PickUp]: '16:00',
    [CareTypeNew.PlayOnly]: '16:00',
    // [CareType.Cleaning]: '19:00',
    // [CareType.Cooking]: '19:00',
    // [CareType.Premium]: '19:00',
  }[type]);
export const getEndTimePlaceholder = (type: CareTypeNew) =>
  ({
    [CareTypeNew.DropOff]: '09:00',
    [CareTypeNew.PickUp]: '19:00',
    [CareTypeNew.PlayOnly]: '19:00',
    // [CareType.Cleaning]: '20:00',
    // [CareType.Cooking]: '20:00',
    // [CareType.Premium]: '20:00',
  }[type]);

// 최소 이용가능시간
export const getMinimunServiceTime = (type: CareTypeNew) =>
  ({
    [CareTypeNew.DropOff]: 2,
    [CareTypeNew.PickUp]: 3,
    [CareTypeNew.PlayOnly]: 3,
    // [CareType.Cleaning]: 1,
    // [CareType.Cooking]: 1,
    // [CareType.Premium]: 1,
  }[type]);
// 최대 이용가능시간
export const getMaximumServiceTime = (type: CareTypeNew) =>
  ({
    [CareTypeNew.DropOff]: 24,
    [CareTypeNew.PickUp]: 24,
    [CareTypeNew.PlayOnly]: 24,
    // [CareType.Cleaning]: 1,
    // [CareType.Cooking]: 1,
    // [CareType.Premium]: 1,
  }[type]);
