import { getAccessToken, getRefreshToken } from './token';
export class Api {
  public token: string = null;
  public refreshToken: string = null;

  constructor(private endpoint: string) {}

  async _fetch(url: string, options: RequestInit) {
    if (!url.startsWith('http')) url = `${this.endpoint}${url}`;
    return fetch(url, options);
  }

  async get(path, params = {}) {
    const q = new URLSearchParams({
      ...params,
    }).toString();

    const st = Date.now();
    console.log(`GET ${path}`, q);
    const resp = await this._fetch(`${path}?${q}`, {
      headers: {
        ...this.getCommonHeaders(),
      },
    });
    const text = await resp.text();
    console.log('GET', Date.now() - st, text);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400) throw json;
      return json;
    } catch (e) {
      if (resp.status >= 400)
        throw { message: 'Error', status: resp.status, body: text };
      return text;
    }
  }
  async post(path, param) {
    console.log(`POST ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'POST',
      headers: {
        ...this.getCommonHeaders(),
      },
      body: JSON.stringify(param),
    });
    const text = await resp.text();
    console.log(`POST ${path}`, text);

    if (text.length === 0 && resp.status < 400) return '';

    try {
      const json = JSON.parse(text);
      if (resp.status >= 400) throw json;
      return json;
    } catch (e) {
      if (typeof e === 'string')
        throw { message: 'Error', status: resp.status, body: text };
      throw e;
      //return text;
    }
  }
  async postFile(path, params = {}) {
    console.log(`POSTFILE ${path}`, params);
    const data = new FormData();
    for (const p of Object.keys(params)) {
      const v = params[p];
      if (Array.isArray(v) && p !== 'files') data.append(p, v.join(','));
      else if (p === 'files') {
        v.map(x => {
          console.log('append ', x);
          data.append('files[]', x);
        });
      } else data.append(p, v);
    }

    const resp = await this._fetch(`${path}`, {
      method: 'POST',
      headers: {
        ['Content-Disposition']: 'attachment',
        ['Content-Type']: 'multipart/form-data',
        ['Authorization']: `Bearer ${this.token}`,
      },
      body: data,
    });
    const json = await resp.json();
    console.log('POSTFILE', json);
    return json;
  }
  async delete(path, param) {
    console.log(`DELETE ${path}`);

    const resp = await this._fetch(`${path}`, {
      method: 'DELETE',
      headers: {
        ...this.getCommonHeaders(),
      },
      body: JSON.stringify(param),
    });
    const text = await resp.text();
    console.log(`DELETE ${path}`, text);
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400) throw json;
      return json;
    } catch (e) {
      if (resp.status >= 400)
        throw { message: 'Error', status: resp.status, body: text };
      return text;
    }
  }
  async patch(path, param) {
    console.log(`PATCH ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'PATCH',
      headers: {
        ...this.getCommonHeaders(),
      },
      body: JSON.stringify(param),
    });
    const json = await resp.json();
    console.log('PATCH', json);
    return json;
  }
  async put(path, param) {
    console.log(`PUT ${path}`, param);
    const resp = await this._fetch(`${path}`, {
      method: 'PUT',
      headers: {
        ...this.getCommonHeaders(),
      },
      body: JSON.stringify(param),
    });
    const text = await resp.text();
    try {
      const json = JSON.parse(text);
      if (resp.status >= 400) throw new json();
      return json;
    } catch (e) {
      if (resp.status >= 400) throw { message: 'Error' };
      return text;
    }
  }

  getCommonHeaders() {
    return {
      ['Content-Type']: 'application/json',
      ['Authorization']: this.token ? `Bearer ${this.token}` : null,
      ['refresh']: this.refreshToken ? `${this.refreshToken}` : null,
    };
  }
}

// 서버자체가 두개임
//export const api = new Api(process.env.ENDPOINT || 'http://15.165.102.16/api');
const api = new Api(process.env.ENDPOINT || 'https://api.woorihero.com/api');
//const api = new Api('http://localhost:3001/api');
const apiSitter = new Api(
  process.env.ENDPOINT_SITTER || 'https://api2.woorihero.com/api',
);

const apiNew = new Api(process.env.REACT_APP_API_URL);

apiNew.token = getAccessToken();
apiNew.refreshToken = getRefreshToken();

api.token = sessionStorage.getItem('token');

// @ts-ignore
window.api = api;
// @ts-ignore
window.apiSitter = apiSitter;

// @ts-ignore
window.apiNew = apiNew;
