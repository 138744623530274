import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { RoundButton } from 'atom/button';
import {
  Gray18,
  LightGray12,
  LightGray10,
  Gray25,
  LightGray14,
} from 'atom/text';
import { HorizontalLayout, Space, Push } from 'atom/layout';
import { Header } from 'component/header';
import { Footer } from 'component/footer';
import { Color, URL } from 'constant';
import { usePrices } from 'state/hook/value';
import { price } from 'util/index';

export const CustomerPricingPage = ({}) => {
  const history = useHistory();

  return (
    <>
      <Header />
      <Container>
        <Space height={130} />
        <Title>
          우리동네 돌봄히어로
          <br />
          서비스 이용요금
        </Title>
        <Space height={55} />

        <Gray25 bold center>
          기본 돌봄서비스
        </Gray25>
        <Space height={32} />

        <SectionText>서비스 구분</SectionText>
        <Space height={18} />
        <ServiceCategoryTable />
        <Space height={77} />

        <SectionText>시간당 요금</SectionText>
        <Space height={18} />
        <HourlyPricingTable />
        <Space height={12} />
        <LightGray14 right style={{ width: '620px', fontSize: '13px' }}>
          * 공휴일/주말/야간 이용시 비용이 추가됩니다.
          <br />
          *선생님 경력에 따라 돌봄료가 달라질 수 있습니다.
        </LightGray14>
        <Space height={77} />

        <SectionText>서비스 종류 및 최소시간</SectionText>
        <Space height={18} />
        <ServiceMinTable />
        <Space height={77} />

        <Gray25 bold center>
          창의놀이 패키지
        </Gray25>
        <Space height={32} />

        <SectionText>서비스 종류 및 비용</SectionText>
        <Space height={18} />
        <PlayKitPackageTable />
        <Space height={18} />
        <LightGray14 right style={{ width: '620px', fontSize: '13px' }}>
          *창의놀이는 비정기 돌봄 신청시에는 이용이 불가능합니다.
          <br />
          *창의놀이는 기본돌봄 시간에 포함되어 진행됩니다.
          <br />
          *패키지비용은 기본 돌봄 이용 이외 별도 비용으로,
          <br />
          놀이 재료 및 서비스 추가 비용 모두 포함된 금액입니다.
          <br />
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            *서비스 시작 시 최초 1회 필수 준비물 비용 30,000원이 부과됩니다.
          </span>
        </LightGray14>
        <Space height={50} />

        {/* <SectionText>시간당 요금</SectionText>
        <Space height={18} />
        <ExtraHourlyPricingTable />
        <Space height={18} />
        <LightGray12 style={{ width: '620px', textAlign: 'right' }}>
          *공휴일/주말/야간 이용시 2,000원이 추가됩니다.
          <br />
          *서비스 시작시 최초 1회 필수 준비물 비용 30,000원이 부과됩니다.
        </LightGray12>
        <Space height={77} /> */}

        <Gray18>
          - 정기돌봄의 경우, 최소 한 달, 주 2회 이상 이용할 경우 해당됩니다
          <br />
          - 최소시간에 맞출 수 없는 경우 히어로 센터에 개별 문의해주세요
          <br />- 19:00시 부터 7:00까지는 야간돌봄으로 필요할 경우 센터에 개별
          문의해주세요
        </Gray18>
        <Space height={72} />

        <HorizontalLayout fill>
          <RoundButton onClick={() => history.push('/service/apply')}>
            돌봄서비스 신청
          </RoundButton>
          <Space width={18} />
          <RoundButton variant="white" onClick={() => window.open(URL.Kakao)}>
            카카오톡 상담하기
          </RoundButton>
        </HorizontalLayout>
        <Space height={220} />
      </Container>
      <Footer />
    </>
  );
};

const ServiceCategoryTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>정기 돌봄</th>
          <th style={{ width: '50%' }}>비정기 돌봄</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>최소 한 달, 주 2회 이상</td>
          <td>1회 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
const HourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 시간</th>
          <th>아이 1명</th>
          <th>아이 2명</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>정기</td>
          <td>{price(prices['정기_신입_1:1_day'])}원</td>
          <td>{price(prices['정기_신입_1:2_day'])}원</td>
        </tr>
        <tr>
          <td>비정기</td>
          <td>{price(prices['비정기_신입_1:1_day'])}원</td>
          <td>{price(prices['비정기_신입_1:2_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
const ServiceMinTable = () => {
  return (
    <Table>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>서비스 종류</th>
          <th style={{ width: '50%' }}>최소시간</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>등원 + 놀이돌봄</td>
          <td>2시간 이상</td>
        </tr>
        <tr>
          <td>하원 + 놀이돌봄</td>
          <td>3시간 이상</td>
        </tr>
        <tr>
          <td>등하원 + 놀이돌봄</td>
          <td>5시간 이상</td>
        </tr>
        <tr>
          <td>놀이돌봄만</td>
          <td>3시간 이상</td>
        </tr>
      </tbody>
    </Table>
  );
};
const ExtraHourlyPricingTable = () => {
  const prices = usePrices();

  return (
    <Table>
      <thead>
        <tr>
          <th>서비스 종류</th>
          <th>이용요금</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의 놀이 1명</td>
          <td>{price(prices['정기_premium_1:1_day'])}원</td>
        </tr>
        <tr>
          <td>창의 놀이 2명</td>
          <td>{price(prices['정기_premium_1:2_day'])}원</td>
        </tr>
        <tr>
          <td>아이중점 가사서비스</td>
          <td>{price(prices['정기_cleaning_1:1_day'])}원</td>
        </tr>
      </tbody>
    </Table>
  );
};
// const ExtraServiceMinTable = () => {
//   return (
//     <Table>
//       <thead>
//         <tr>
//           <th>서비스 종류</th>
//           <th>최대 이용시간</th>
//         </tr>
//       </thead>
//       <tbody>
//         <tr>
//           <td>창의 놀이</td>
//           <td>1시간</td>
//         </tr>
//         <tr>
//           <td>아이중점 가사서비스</td>
//           <td>1시간</td>
//         </tr>
//       </tbody>
//     </Table>
//   );
// };

const PlayKitPackageTable = () => {
  return (
    <Table style={{ width: '100%', maxWidth: '620px' }}>
      <thead>
        <tr>
          <th style={{ width: '50%' }}>놀이 횟수</th>
          <th style={{ width: '50%' }}>가격</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>12회 패키지 (4주 기준)</td>
          <td>
            <span style={{ color: '#969696', textDecoration: 'line-through' }}>
              120,000원
            </span>
            <br />
            <span style={{ color: '#F69F30', fontWeight: 'bold' }}>
              25% 할인
            </span>
            &nbsp;90,000원
          </td>
        </tr>
        <tr>
          <td>8회 패키지 (4주 기준)</td>
          <td>
            <span style={{ color: '#969696', textDecoration: 'line-through' }}>
              80,000원
            </span>
            <br />
            <span style={{ color: '#F69F30', fontWeight: 'bold' }}>
              13% 할인
            </span>
            &nbsp;70,000원
          </td>
        </tr>
        <tr>
          <td>4회 패키지 (4주 기준)</td>
          <td>40,000원</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 619px;

  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  td,
  th {
    border: 1px solid white;
  }
  thead {
    background: ${Color.Primary};
  }
  tbody tr:nth-child(2n + 1) {
    background: #f5f5f5;
  }
  tbody tr:nth-child(2n + 2) {
    background: #f9f9f9;
  }
  th {
    color: white;

    font-size: 14px;
    font-weight: bold;

    padding: 10px 10px;
  }
  td {
    color: #484848;

    font-size: 14px;
    font-weight: 500;
    text-align: center;

    line-height: 1.43;
    letter-spacing: -0.7px;

    padding: 20px 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  text-align: center;

  line-height: 1.38;
  letter-spacing: -2px;
`;
const SectionText = styled.div`
  color: #484848;

  font-size: 19px;
  font-weight: bold;
  text-align: center;
`;
