import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';
import { intersection, difference } from 'lodash';

import { WeekdayPicker } from 'atom/input';
import { NoCareData } from 'atom/care';
import { Push, Space, HorizontalLayout, VerticalLayout } from 'atom/layout';
import { UnderlinedText, Gray16, Primary16 } from 'atom/text';
import { TimeRangePopup } from 'component/popup';
import {
  getEndTimePlaceholder,
  getMaximumServiceTime,
  getMinimunServiceTime,
  getStartTimePlaceholder,
} from 'constant';
import { useStores } from 'state';
import { CareType, CareTypeNew, isAddonCareType } from 'model';
import { useTopmost } from 'util/index';
import { Title, Subtitle } from './style';
import { RequestStep6 } from './RequestStep6';
import { RequestStepHeroType } from '.';
import _ from 'lodash';
const Weekdays = ['월', '화', '수', '목', '금', '토', '일'];

const joinWeekdays = (x: number[]) => {
  return x.map(x => Weekdays[x]).join(', ');
};

const service = {
  [CareTypeNew.PickUp]: '하원 + 놀이돌봄',
  [CareTypeNew.DropOff]: '등원 + 놀이돌봄',
  [CareTypeNew.PlayOnly]: '놀이돌봄만',
};
export const RequestStep5Regular = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const [weekday, setWeekday] = useState<number[]>([]);
  //console.log(customerStore.form.careTypes);

  const [inputs, setInputs] = useState(
    customerStore.applyForm.careTypes.map(x => ({
      type: x,
      startTime: null,
      endTime: null,
    })),
    //TODO 순서 정렬 체크
    // .sort((a, b) => {
    //   if (a.type === CareTypeNew.DropOff) {
    //     return -1;
    //   } else if (b.type === CareTypeNew.DropOff) {
    //     return 1;
    //   } else if (a.type === CareTypeNew.PickUp) {
    //     if (b.type === CareTypeNew.PlayOnly) {
    //       return 1;
    //     }
    //     return -1;
    //   } else {
    //     return 1;
    //   }
    // }),
  );
  const [schedules, setSchedules] = useState(customerStore.applyForm.schedules);

  const isOnlyPlayCare = customerStore.applyForm.careTypes.includes(
    CareTypeNew.PlayOnly,
  );
  // const hasExtraService =
  //   customerStore.form.careTypes.includes(CareType.Cooking) ||
  //   customerStore.form.careTypes.includes(CareType.Cleaning) ||
  //   customerStore.form.careTypes.includes(CareType.Premium);

  // const hasPremiumService = customerStore.form.careTypes.includes(
  //   CareType.Premium,
  // );
  const hasPickupOrDropoff =
    customerStore.applyForm.careTypes.includes(CareTypeNew.PickUp) ||
    customerStore.applyForm.careTypes.includes(CareTypeNew.DropOff);

  // const isAbleToPremium =
  //   customerStore.form.careTypes.includes(CareType.PickUp) ||
  //   customerStore.form.careTypes.includes(CareType.PlayOnly);

  const onClickAdd = schedule => {
    setInputs(
      customerStore.applyForm.careTypes.map(x => ({
        type: x,
        startTime: null,
        endTime: null,
      })),
    );
    if (weekday.length === 0) {
      showConfirm('요일을 선택해주세요.');
      return;
    }
    if (
      schedules.some(x =>
        inputs
          .filter(x => !!x.startTime)
          .some(
            y =>
              intersection(weekday, x.weekday).length > 0 && y.type === x.type,
          ),
      )
    ) {
      showConfirm('이미 등록된 요일이에요.');
      return;
    }
    // addon서비스일 때,
    // weekday의 값들이 schedules에 등록된 요일이 아닌 요일이 있다면
    // 경고 : 추가서비스는 기본놀이돌봄이 있는 날에만 가능해요.
    // if (isAddonCareType(schedule.type)) {
    //   const mergedDays = [].concat.apply(
    //     [],
    //     schedules.map(x => x.weekday),
    //   );
    //   const uniqueDays = new Set(mergedDays);
    //   const ableDays = Array.from(uniqueDays); // 스케줄에 등록된 요일배열.

    //   const isAbleDay = difference(weekday, ableDays).length === 0; // weekday가 ableDays에 포함된다면 true
    //   if (!isAbleDay) {
    //     showConfirm('추가서비스는 기본놀이돌봄이 있는 날에만 가능해요.');
    //     return;
    //   }
    //   console.log(isAbleDay);
    // }

    //창의 놀이일 경우 ,

    // if (schedule.type === CareType.Premium) {
    //   if (weekday.length > 2) {
    //     showConfirm('창의놀이는 최대 주 2회까지 이용이 가능해요.');
    //     return;
    //   }
    //   // if (isAbleToPremium === false) {
    //   //   // 하원 || 놀이돌봄만 신청한 경우에만 등록할 수 있도록 알림처리.
    //   //   showConfirm(
    //   //     '창의놀이는 [하원] 또는 [놀이돌봄만] 있는 날에만 가능해요.',
    //   //   );
    //   //   return;
    //   // }
    // }

    inputs
      .filter(x => !!x.startTime)
      .forEach(input => {
        setSchedules(schedules => [
          ...schedules,
          {
            weekday,
            ...input,
          },
        ]);
      });
    setWeekday([]);
    // setInputs(
    //   customerStore.form.careTypes.map(x => ({
    //     type: x,
    //     startTime: null,
    //     endTime: null,
    //   })),
    // );
  };
  const onChangeTime = (schedule: any, start: string, end: string) => {
    if (!isAddonCareType(schedule.type)) return true;

    const ret = schedules
      .filter(x => x.startTime)
      .filter(x => !isAddonCareType(x.type))
      .filter(x => weekday.every(y => x.weekday.includes(y)))
      .some(x => {
        const s1 = DateTime.fromFormat(start, 'hh:mm');
        const e1 = DateTime.fromFormat(x.endTime, 'hh:mm');
        const s2 = DateTime.fromFormat(x.startTime, 'hh:mm');
        if (
          s1.diff(s2, 'hours').hours === -1 ||
          e1.diff(s1, 'hours').hours === 0
        ) {
          return true;
        } else {
          return false;
        }
      });

    if (!ret)
      showConfirm(schedule.type + '는(은) 돌봄 시간 전후로 설정해주세요.');

    return ret;
  };
  const onClickNext = () => {
    // if (
    //   schedules.filter(x => x.type === CareType.Premium).flatMap(x => x.weekday)
    //     .length >= 3
    // ) {
    //   showConfirm('창의놀이는 최대 주 2회까지 이용이 가능해요.');
    //   return;
    // }
    if (_.uniq(schedules.flatMap(x => x.weekday)).length < 2)
      showConfirm('주 1회 서비스를 이용할 경우\n비정기 요금으로 정산됩니다');
    onNext(RequestStepHeroType);
  };

  const checkAllSchedulesDone = () => {
    // const res = inputs.length <= schedules.length;
    // return !res;
    if (inputs.length > schedules.length) return true;
  };
  const isDisable = input => {
    if (isAddonCareType(input.type)) {
      // addon서비스인 경우,
      // if (input.type === CareType.Premium) {
      //   // 그중 창의놀이인경우,
      //   return schedules
      //     .filter(x => !isAddonCareType(x.type) && x.type !== CareType.DropOff)
      //     .every(x => !x.startTime);
      // }
      return schedules
        .filter(x => !isAddonCareType(x.type))
        .every(x => !x.startTime);
    }
  };
  console.log(schedules);
  useEffect(() => {
    //이전화면에서 선택한 돌봄서비스 + AddOn서비스만 스케줄에 남도록 필터링 하기.
    const _schedules = customerStore.applyForm.schedules.filter(schedule =>
      customerStore.applyForm.careTypes.includes(schedule.type),
    );
    setSchedules(_schedules);
  }, []);

  useEffect(() => {
    customerStore.applyForm.schedules = schedules;
  }, [customerStore.applyForm, schedules]);
  // useEffect(() => {
  //   customerStore.form.schedules = schedules;
  //   // customerStore.form.careService = schedules
  //   //   .map(
  //   //     x =>
  //   //       `${joinWeekdays(x.weekday)} / ${x.type} / ${x.startTime}~${
  //   //         x.endTime
  //   //       }`,
  //   //   )
  //   //   .join('\n');
  //   // console.log(customerStore.form.careService);
  // }, [schedules]);

  return (
    <>
      <Title>
        돌봄이 필요한 요일과
        <br />
        시간을 알려주세요.
      </Title>
      <Space height={18} />
      <Subtitle>
        <div>{'*정기 돌봄은 최소 주 2회 이상 신청해야 해요.'}</div>
        {hasPickupOrDropoff && (
          <div>
            {'*등원은 최소 2시간, 하원은 최소 3시간 이상부터 가능해요.'}
          </div>
        )}

        {isOnlyPlayCare && (
          <div>{'*놀이돌봄은 최소 3시간 이상부터 가능해요. '}</div>
        )}

        {/* {hasExtraService && (
          <div>
            {hasPremiumService
              ? '*창의놀이는 최대 1시간, 주 2회까지 이용 가능합니다.'
              : '*청소/요리 서비스의 경우, 아이가 없는 시간에만 이용 가능합니다.'}
          </div>
        )} */}
      </Subtitle>
      <Space height={52} />

      <WeekdayPicker value={weekday} onChange={setWeekday} />
      <Space height={17} />
      <hr />
      <Space height={17} />

      {inputs.map((x, idx) => (
        <React.Fragment key={idx + schedules.length * 10}>
          <CareTimeItem
            type={x.type}
            startTime={x.startTime}
            endTime={x.endTime}
            disabled={isDisable(x)}
            onChange={(start, end) => {
              if (onChangeTime(x, start, end)) {
                inputs[idx].startTime = start;
                inputs[idx].endTime = end;
                setInputs([...inputs]);

                onClickAdd(x);
              }
            }}
          />
          <Space height={14} />
        </React.Fragment>
      ))}
      <Space height={3} />
      <hr />
      <Space height={26} />

      {schedules.length === 0 && (
        <>
          <NoCareData />
          <Space height={20} />
        </>
      )}
      {schedules.map((x, idx) => (
        <React.Fragment key={idx}>
          <CareScheduleItem
            type={x.type}
            weekday={x.weekday}
            startTime={x.startTime}
            endTime={x.endTime}
            onRemove={() => {
              setSchedules(schedules.filter(y => y !== x));
            }}
          />
          <Space height={17} />
        </React.Fragment>
      ))}
      <Space height={10} />

      {/* <UnderlinedText onClick={onClickAdd}>+ 돌봄일 추가하기</UnderlinedText> */}
      <Space height={62} />
      <Bottom
        progress={(1 / 11) * 5}
        disabled={checkAllSchedulesDone()}
        onClickNext={onClickNext}
      />
    </>
  );
});

interface CareScheduleItemProps {
  type: CareTypeNew;
  weekday: number[];
  startTime: string;
  endTime: string;
  onRemove: () => void;
}
const CareScheduleItem = ({
  type,
  weekday,
  startTime,
  endTime,
  onRemove,
}: CareScheduleItemProps) => {
  return (
    <CareScheduleItemContainer center>
      <VerticalLayout>
        <Primary16 semiBold>
          {weekday.map(x => Weekdays[x]).join(', ')}
        </Primary16>
        <Gray16 semiBold>
          {service[type]} / {startTime} ~ {endTime}
        </Gray16>
      </VerticalLayout>
      <Push />
      <CloseIcon onClick={onRemove} />
    </CareScheduleItemContainer>
  );
};

interface CareTimeItemProps {
  type: CareTypeNew;
  startTime: string;
  endTime: string;
  disabled?: boolean;
  onChange: (startTime: string, endTime: string) => void;
}
const CareTimeItem = observer(
  ({ type, startTime, endTime, disabled, onChange }: CareTimeItemProps) => {
    const topmost = useTopmost();
    const onClickTime = e => {
      const target = e.target;
      const id = topmost.show(
        <TimeRangePopup
          type={type}
          min={getMinimunServiceTime(type)}
          max={getMaximumServiceTime(type)}
          start={startTime || getStartTimePlaceholder(type)}
          end={endTime || getEndTimePlaceholder(type)}
          onSubmit={(start, end) => {
            onChange(start, end);
          }}
          onClose={() => topmost.dismiss(id)}
        />,
      );
    };

    return (
      <HorizontalLayout
        center
        style={{
          opacity: disabled ? '0.6' : 1,
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <CareTypeText>{service[type]}</CareTypeText>
        <Push />
        <CareTimeInput
          placeholder={getStartTimePlaceholder(type)}
          value={startTime}
          onClick={onClickTime}
        />
        <Space width={17} />
        <CareTypeText>~</CareTypeText>
        <Space width={17} />
        <CareTimeInput
          placeholder={getEndTimePlaceholder(type)}
          value={endTime}
          onClick={onClickTime}
        />
      </HorizontalLayout>
    );
  },
);

const CareTypeText = styled.div`
  color: #484848;

  font-size: 16px;
  font-weight: 500;

  letter-spacing: -0.16px;
`;
const CareTimeInput = styled(TextField).attrs({
  variant: 'outlined',
  style: { width: '105px' },
  InputLabelProps: {
    shrink: true,
  },
  InputProps: {
    readOnly: true,
  },
})`
  input {
    text-align: center;
    user-select: none;
  }
`;

const CareScheduleItemContainer = styled(HorizontalLayout)`
  width: 100%;
  height: 76px;

  box-shadow: 3px 4px 12px 0 #dedede;
  background-color: #fff;
  border-radius: 6px;

  padding: 6px 24px;
`;
const CloseIcon = styled.img.attrs({
  src: require('asset/common/close.svg').default,
})`
  width: 10px;
  height: 10px;

  cursor: pointer;
`;
