import React, { useState } from 'react';
import styled from 'styled-components';
import WheelPicker from 'react-simple-wheel-picker';
import { DateTime } from 'luxon';

import { Checkbox, Labeled } from 'atom/input';
import { Gray12, Gray13, Black14 } from 'atom/text';
import { Push, Space, HorizontalLayout } from 'atom/layout';
import { Color } from 'constant';
import { Backdrop } from './Backdrop';
import { CareType, CareTypeNew } from 'model';

const TimeItems = [...new Array(24 * 2)].map((x, idx) => ({
  value: `${DateTime.fromSeconds(0)
    .toUTC()
    .plus({ minutes: 30 * idx })
    .toFormat('HH:mm')}`,
  id: `${idx}`,
}));

const service = {
  [CareTypeNew.PickUp]: '하원 + 놀이돌봄',
  [CareTypeNew.DropOff]: '등원 + 놀이돌봄',
  [CareTypeNew.PlayOnly]: '놀이돌봄만',
};

interface TimeRangePopupProps {
  type: CareTypeNew;
  min?: number; // 최소 시간 간격
  max?: number; // 최대 시간
  start: string;
  end: string;
  onSubmit: (start: string, end: string) => void;
  onClose: () => void;
}
export const TimeRangePopup = ({
  type,
  min = 0,
  max = 24,
  start,
  end,
  onSubmit,
  onClose,
}: TimeRangePopupProps) => {
  const [startTime, setStartTime] = useState(
    TimeItems.find(x => x.value === start)?.id || '20',
  );
  const [endTime, setEndTime] = useState(
    TimeItems.find(x => x.value === end)?.id || '28',
  );

  const onComplete = () => {
    if (startTime >= endTime) {
      showConfirm('시작 시간은 종료 시간보다 이른 시간으로 설정해주세요.');
      return;
    }
    if (+endTime - +startTime < min * 2) {
      showConfirm(`${service[type]}은 최소 ${min}시간 이상부터 가능해요.`);
      return;
    }
    if (+endTime - +startTime > max * 2) {
      showConfirm(`${service[type]}은 최대 ${max}시간까지 가능해요.`);
      return;
    }

    onSubmit(
      TimeItems.find(x => x.id === startTime)?.value,
      TimeItems.find(x => x.id === endTime)?.value,
    );
    onClose();
  };

  const getTypeText = () => {
    switch (type) {
      // case CareType.Cleaning:
      // case CareType.Cooking:
      //   return `*가사서비스는 최대 1시간까지 가능해요.
      //   *가사서비스는 돌봄 시간 전후로 설정해주세요.`;
      //   break;

      // case CareType.Premium:
      //   return `*창의놀이는 최대 1시간까지 가능해요.
      // *창의놀이는 돌봄 시간 전후로 설정해주세요.`;
      //   break;
      default:
        return `*등원은 최소 2시간, 하원은 최소 3시간 이상부터 가능해요.`;
        break;
    }
  };
  return (
    <Backdrop onClose={onClose}>
      <Container onClick={e => e.stopPropagation()}>
        <FormContainer>
          <Labeled
            label="가능한 시간대 설정"
            labelStyle={{ fontWeight: 'bold' }}
          >
            <WheelPickerContainer center>
              <Push />
              <SWheelPicker
                data={TimeItems}
                height={150}
                width={100}
                itemHeight={50}
                color="#ccc"
                focusColor="white"
                activeColor="#f69f30"
                backgroundColor="#fff"
                selectedID={startTime}
                onChange={e => setStartTime(e.id)}
              />
              <Push />
              <div
                style={{
                  color: Color.Primary,
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
              >
                ~
              </div>
              <Push />
              <SWheelPicker
                data={TimeItems}
                height={150}
                width={100}
                itemHeight={50}
                color="#ccc"
                focusColor="white"
                activeColor="#f69f30"
                backgroundColor="#fff"
                selectedID={endTime}
                onChange={e => setEndTime(e.id)}
              />
              <Push />
              <div
                style={{
                  position: 'absolute',
                  left: '20px',
                  top: '50px',
                  width: 'calc(100% - 40px)',
                  height: '0.5px',
                  background: '#b7b7b7',
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  left: '20px',
                  top: '100px',
                  width: 'calc(100% - 40px)',
                  height: '0.5px',
                  background: '#b7b7b7',
                }}
              />
            </WheelPickerContainer>
          </Labeled>
          <Space height={20} />
          <Gray12 semiBold center>
            {getTypeText()}
          </Gray12>
        </FormContainer>

        <CloseButton onClick={onClose} />
        <BottomButton onClick={onComplete}>추가하기</BottomButton>
      </Container>
    </Backdrop>
  );
};

const Container = styled.div`
  position: relative;

  width: 400px;

  border-radius: 10px;
  background-color: #fff;

  overflow: hidden;

  --animate-duration: 0.4s;
`;
const CloseButton = styled.img.attrs({
  src: require('asset/common/close.svg').default,
})`
  position: absolute;
  right: 16px;
  top: 24px;
  width: 15px;
  height: 15px;

  cursor: pointer;
`;
const FormContainer = styled.form`
  padding: 24px 24px;
`;
const WheelPickerContainer = styled(HorizontalLayout)`
  position: relative;

  border: 1px solid #b7b7b7;
  border-radius: 8px;

  ul {
    box-shadow: none !important;
    background: none !important;
  }
  ul::-webkit-scrollbar {
    display: none;
  }
  li span:nth-child(1) {
    color: white;
  }
  li[aria-selected='true'] {
    font-weight: bold;
  }
  ul * {
    user-select: none;
  }
`;
const SWheelPicker = styled(WheelPicker)``;
const BottomButton = styled.div`
  display: flex;
  width: 100%;
  height: 52px;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: white;

  background-color: ${Color.Primary};

  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
