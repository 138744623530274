import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { Space } from 'atom/layout';
import { useStores } from 'state';
import { Title, Subtitle } from './style';
import { RequestStep12 } from './RequestStep12';
import { useAuthContext } from '../../../context/AuthContext';
import { ApplySignupStep1 } from '../register/ApplySignupStep1';

export const RequestStep11 = observer(({ Bottom, onNext }) => {
  const { customerStore } = useStores();
  const { isLogin } = useAuthContext();

  const onClickNext = () => {
    if (isLogin) return onNext(RequestStep12); //쿠폰확인으로 연결
    return onNext(ApplySignupStep1); //회원가입으로 연결
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Title>
        돌봄 중 요청사항을
        <br />
        적어주세요
      </Title>
      <Space height={8} />
      <Subtitle>
        *성향, 병력, 낮잠시간, 기저귀 착용여부 등 자유롭게 적어주세요.
        <br />
        *아이 외부 스케쥴도 함께 적어주세요.
      </Subtitle>
      <Space height={51} />

      <textarea
        placeholder="입력란 (200자 이내)"
        maxLength={200}
        value={customerStore.applyForm.userRequest}
        onChange={e => (customerStore.applyForm.userRequest = e.target.value)}
      />
      <Space height={60} />
      <Bottom
        disabled={customerStore.applyForm.userRequest.length === 0}
        progress={(1 / 11) * 11}
        onClickNext={onClickNext}
      />
    </>
  );
});
