import { Space } from 'atom/layout';
import { DarkGray20, DarkGray24 } from 'atom/text';
import React from 'react';
import styled from 'styled-components';

export const SupportInfos = () => {
  return (
    <Container>
      <div style={{ textAlign: 'center', margin: '0 20px' }}>
        <img
          src={require('asset/event/event_sd/suport1.jpg').default}
          style={{ width: '378px' }}
        />
        <Space height={30} />
        <DarkGray24 style={{ fontWeight: 500 }}>
          든든한 활동지원 제도
        </DarkGray24>
        <Space height={24} />
        <DarkGray20
          style={{
            textAlign: 'start',
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: 0,
          }}
        >
          · 교육 한 번만으로 지속적인 일자리 연결
          <br />· 집근처 30분 거리, 원하는 시간에 활동 가능
        </DarkGray20>
      </div>
      <div style={{ textAlign: 'center', margin: '0 20px' }}>
        <img
          src={require('asset/event/event_sd/suport2.jpg').default}
          style={{ width: '378px' }}
        />
        <Space height={30} />
        <DarkGray24 style={{ fontWeight: 500 }}>
          지속적인 성장지원 제도
        </DarkGray24>
        <Space height={24} />
        <DarkGray20
          style={{
            textAlign: 'start',
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: 0,
            marginLeft: '30px',
          }}
        >
          · 보수교육을 통한 역량개발 지원 <br />
          · 송년회, 워크샵 등 네트워크 지원
          <br />
          · 1000가지 히어로 놀이법 제공
          <br />· 아이 기질/연령/성향별 돌봄팁 제공
        </DarkGray20>
      </div>
      <div style={{ textAlign: 'center', margin: '0 20px' }}>
        <img
          src={require('asset/event/event_sd/suport3.jpg').default}
          style={{ width: '378px' }}
        />
        <Space height={30} />
        <DarkGray24 style={{ fontWeight: 500 }}>확실한 보상제도</DarkGray24>
        <Space height={24} />
        <DarkGray20
          style={{
            textAlign: 'start',
            fontWeight: 300,
            lineHeight: 1.5,
            letterSpacing: 0,
            marginLeft: '20px',
          }}
        >
          · 근무기간에 따른 시급인상
          <br />
          (최소 10,000원 ~ 최대 20,000원) <br />
          · 장기근무 시 아동놀이코치 2급 자격증 발급 <br />
          · 놀이키트 제공 <br />
          · 배상책임보험 지원 <br />
        </DarkGray20>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;
